<template>
  <div class="about">
    <b-card>
      <b-card-text class="text-center"><h2>Trade Access Crm</h2></b-card-text>
      <b-card-text>
        <h5>Our vision:</h5>
        <p> A client trades management CRM that goes beyond just a software solution – it's about empowering businesses to thrive in a dynamic and competitive landscape.
          With our innovative platform, businesses can streamline trade processes, enhance client relationships, drive growth, and build a brighter future together.
        </p>
        <p>A software of Trade Access Crm. © 2021.</p>
      </b-card-text>
    </b-card>
    <b-card>
      <b-card-text><h4 class="mb-2">Version logs</h4></b-card-text>
      <b-card-text>
        <h5>1.0.0</h5>
        <p></p>
        <div>
<!--          <b-img thumbnail fluid v-for="(img, index) in imgs['1.0.11']" class="cursor-pointer"
                 v-bind:key="`[1.0.11]-${index}`" :src="img.src" :title="img.title"
                 @click="()=>{$refs.imageModal.showModal(imgs['1.0.11'], index)}"
          />-->
        </div>
      </b-card-text>
    </b-card>
    <image-modal ref="imageModal"/>
  </div>
</template>

<script>
import ImageModal from '@/components/modal/imageModal'
export default {
  name: 'About',
  components: { ImageModal },
  data: () => {
    return {
      imgs:{
        '1.0.11': [
          // {src: require('@/assets/images/logs/cumprimento-de-sentenca.png'), title: 'cumprimento de sentenca na busca'},
        ],
      }
    }
  },
}
</script>

<style lang="scss">
.about {
  .img-thumbnail {
    max-height: 100px;
    margin-right: 25px;
  }
}
</style>
